/* ServiceCard.css */
.service-cards-container {
  text-align: center;
}

.title-container {
  margin-bottom: 20px;
  margin-top: 20px;
}

h1 {
  font-size: 26px;
}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
  text-align: center;
}

.card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  transition: transform 0.3s ease-in-out, box-shadow 0.1s ease-in-out;
  width: 250px;
  height: 280px;
  box-shadow: 14px 14px 32px rgba(1, 2, 5, 0.3);
}


.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  position: relative;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  z-index: 0;
}

.card:hover {
  transform: perspective(1000px) scale(1.1);
  box-shadow: 32 32px 54px rgba(0, 0, 0, 0.2);
}

.card:hover .card-image {
  transform: scale(1.1);
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 0px;
  border-radius: 0 0 8px 8px;
  transition: background-color 0.3s ease-in-out;
}

.card-content h2 {
  color: #333;
  font-size: 1.25rem;
  text-decoration: none;
}

.card-content p {
  color: #666;
  font-size: 0.85rem;
}


/*@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.service-cards-container {
  transition: none;
  opacity: 0;
  animation: fade-in 2.2s ease-out forwards;
}
*/