.position-container {
  text-align: center;
}

.columns {
  display: flex;
  align-items: center;
}

.contacts-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 10vh;
}

.contacts-column p {
  margin-bottom: 2vh;
}

.contacts-column h3 {
  font-size: 18px;
}

.contacts-column svg {
  margin-right: 8px;
}

.map-column {
  flex: 1;
  background-color: #ffffff00;
}

.map-column iframe {
  border-radius: 2vh;
}

.map-column iframe:hover {
  transform: scale(1.02); /* Effetto di zoom al passaggio del mouse */
  transition: transform 0.2s ease-out; /* Aggiungi una transizione per rendere l'animazione più fluida */
}

.social-media-icons {
  display: flex;
  justify-content: left;
  gap: 5vh; /* Distanzia leggermente le icone dal testo sia in desktop che in mobile */
  margin-top: 3vh;
}

.social-media-icons svg {
  color: #333;
  font-size: 27px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.social-media-icons a[href*="instagram.com"] svg:hover {
  color: #bc2a8d; /* Colore distintivo di Instagram al passaggio del mouse */
  transform: scale(1.1);
}

.social-media-icons a[href*="wa.me"] svg:hover {
  color: #25d366; /* Colore distintivo di WhatsApp al passaggio del mouse */
  transform: scale(1.1);
}

.social-media-icons a[href*="facebook.com"] svg:hover {
  color: #1877f2; /* Colore distintivo di Facebook al passaggio del mouse */
  transform: scale(1.1);
}

.social-media-icons a[href*="linkedin.com"] svg:hover {
  color: #0077b5; /* Colore distintivo più scuro di LinkedIn al passaggio del mouse */
  transform: scale(1.1);
}

/* Stili per dispositivi con larghezza inferiore a 768px (ad esempio, dispositivi mobili) */
@media (max-width: 768px) {
  .columns {
    flex-direction: column;
    text-align: center;
  }

  .contacts-column {
    margin-left: 0;
    text-align: justify; 
    margin-bottom: 20px;
    text-align: center;
  }

  .contacts-column h3 {
    text-align: center;
    font-size: 23px;
  }

  .map-column {
    padding: 10px;
    margin-bottom: 20px;
  }

  .map-column iframe {
    border-radius: 2vh;
    width: 100%;
    height: 300px;
  }

  .social-media-icons {
    justify-content: center;
    margin-top: 20px;
    gap: 40px; 
    margin-bottom: 20px;
  }

  .contacts-column p {
    font-size: 20px;
  }
}

/* @keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.position-container {
  transition: none;
  opacity: 0;
  animation: fade-in 2.2s ease-out forwards;
} 
*/