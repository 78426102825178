body {
  margin: auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow: scroll;
  /* background-color: #f2f2f2;  Colore grigio leggero */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Titolo-scadenza {
  color: rgb(32, 35, 39);
  font-weight: bold;
  /* Per il titolo scadenza che viene dal json di seac */
}
