/* Footer.css */

.footer {
  background-color: #1d1d1d;
  color: #ecf0f1;
  text-align: center;
  padding: 20px;
  position:bottom;
}

.footer p {
  margin: 0;
}
