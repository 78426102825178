:root {   /* Variabili CSS per i valori che si ripetono */
  --white: #ffffff;
  --black: #000000;
  --shadow: 15px 10px 10px rgba(10, 10, 10, 1.5);
  --transition: all 0.3s ease;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shadow {
  text-shadow: var(--shadow);
}

.transition {
  transition: var(--transition);
}

.white {
  color: var(--white);
}

body {
  overflow-x: hidden;
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  opacity: 0.5;
}

.wave-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Altezza dell'onda */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 10%, rgba(255, 255, 255, 0.5) 25%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 1) 100%);
  z-index: 2;
  transform: skewY(-0deg); /* Puoi regolare l'inclinazione dell'onda cambiando questo valore */
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--white);
  padding: 10px; /* Aggiungi un padding per estendere l'area sensibile */
}

.text-overlay h1,
.text-overlay p {
  animation: focus-in-contract 2s ease-out;
  text-shadow: var(--shadow);
  font-family: "Whisper", cursive;
  font-size: 2.3rem;
  letter-spacing: 0.1em; 
}

.text-overlay h1 {
  font-size: 3.8rem;
  margin-bottom: 1px;
  text-shadow: var(--shadow);
  margin-bottom: 11rem; /* Aggiungi spazio sopra l'h1 su dispositivi mobili */
}

.text-overlay p {
  font-size: 2rem;
  text-shadow: 1px 1px 10px rgba(10, 10, 10, 1.5);
  margin-top: 3rem; 
}

.text-overlay:hover h1,
.text-overlay:hover p {
  color: var(--white);
  font-size: 4rem;
  transition: var(--transition);
  text-shadow: 54px 45px 20px rgba(0, 0, 0, 0.75);
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 0.2em;
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    filter: blur(0px);
    opacity: 1;
  }
}

.text-overlay .text-animation,
.text-overlay .text-hidden {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-overlay .text-animation {
  overflow: hidden;
  white-space: nowrap;
  animation: typing 5s steps(40, end);
}

.text-overlay .text-hidden {
  display: none;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.text-overlay:hover .text-animation {
  opacity: 0;
  animation: none;
}

.text-overlay:hover .text-hidden {
  display: block;
}