.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1d1d1d;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brand {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.brand img {
  max-width: 80%;
  height: auto;
  width: auto;
  max-height: 50px;
}

.brand:hover {
  color: #3498db;
  transform: scale(1.03);
}

.nav-links {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-links a {
  color: #ecf0f1;
  text-decoration: none;
  margin: 0 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.nav-links a:hover {
  color: #3498db;
  transform: scale(1.1);
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.hamburger-icon {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.bar {
  width: 30px;
  height: 2px;
  background-color: #ecf0f1;
  transition: background-color 0.5s ease-in-out;
}

/* per la visualizzazione su tablet e mobile */
@media (max-width: 1024px) {
  .navbar {
    position: relative;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #1a1a1a;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 100;
    animation: fadeIn 0.3s ease-in-out forwards;
    font-size: larger;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 1;
    gap: 5px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .menu-icon {
    display: flex;
  }

  .menu-icon .bar {
    display: flex;
  }
}